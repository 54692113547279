
































import Vue from 'vue'
import {
  mapActions,
  mapState
} from 'vuex'
import AddBlock from '@/components/AddBlock.vue'
import ModalEditCatalog from '@/components/Catalogs/ModalEditCatalog.vue'
import Loading from '@/components/Loading/index.vue'
import Add from '@/components/Catalogs/Add.vue'
import CatalogCard from '@/components/Catalogs/CatalogCard.vue'
import { Catalog } from '@/store/modules/catalog/types'

export default Vue.extend({
  name: 'Catalogs',

  components: {
    AddBlock,
    ModalEditCatalog,
    CatalogCard,
    Loading,
    Add
  },

  data () {
    return {
      loading: false,
      localCatalog: null as Catalog
    }
  },

  computed: {
    ...mapState('catalog', [
      'catalogs'
    ]),

    ...mapState('pgt', [
      'pgtProfile'
    ])
  },

  async created () {
    this.fetchCatalogs()
  },

  methods: {
    ...mapActions('catalog', [
      'getCatalogs',
      'updateCatalog'
    ]),

    handleChangeCatalog (catalog: Catalog) {
      this.localCatalog = catalog
      this.showModalEditCatalog()
    },

    async handleSetCatalogAsMain (payload: { catalogId: string, isMain: boolean }) {
      try {
        await this.updateCatalog({
          profileId: this.pgtProfile.business_profile.id,
          catalogId: payload.catalogId,
          data: {
            is_hidden: payload.isMain
          }
        })
        this.$toast.success(payload.isMain ? 'Каталог теперь не основной' : 'Каталог установлен как основной')
      } catch (error) {
        console.info(error)
      }
    },

    showModalEditCatalog () {
      this.$bvModal.show('modal-edit-catalog')
    },

    async fetchCatalogs () {
      try {
        this.loading = true
        await this.getCatalogs({
          businessProfileId: this.pgtProfile.business_profile.id
        })
      } catch {
      } finally {
        this.loading = false
      }
    }
  }
})
